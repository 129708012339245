import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Markdown from 'components/Markdown'
import Button from 'components/Button'

const HeaderSlogan = props => {
  return (
    <div className="section section--padded-big">
      <div className="section__inner">
        <div className="column--measure-medium">
          <h2 className="headline headline--left">{props.title}</h2>
          <h3 className="sub-headline">{props.subtitle}</h3>
          <Markdown>{props.text}</Markdown>
          {props.link ? (
            <Button className="btn--primary" link={props.link}>
              {props.link.label}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

HeaderSlogan.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  link: linkPropType,
}

export default HeaderSlogan
