import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Markdown from 'components/Markdown'
import Button from 'components/Button'
import Helmet from 'react-helmet'
import { jobPosting } from '../../../lib/structured-data'

const JobDescription = props => {
  return (
    <div className="section section--padded-big">
      <div className="center column--measure-medium">
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(jobPosting(props))}
          </script>
        </Helmet>
        <Markdown>{props.text}</Markdown>
        {props.link ? (
          <p className="bt b--black-05 mt2 pt4 tc">
            <Button className="btn--primary" link={props.link}>
              {props.link.label}
            </Button>
          </p>
        ) : null}
      </div>
    </div>
  )
}

JobDescription.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  link: linkPropType,
  jobLocation: PropTypes.string,
  workload: PropTypes.string,
  updatedAt: PropTypes.string,
}

export default JobDescription
